.container-table{
    width: 100%;
    padding: 2em;
    z-index: 2;

    .container-filter{
        width: 100%;
        display: flex;
        justify-content: start;
        gap: 1.5em;
        flex-wrap: wrap;

        label{
            display: flex;
            gap: 15px;
            align-items: baseline;
        }
    }

    table{
        width: 100%;
        border-collapse: collapse;
        margin-top: 2em;

        tr:nth-child(2n){
            background-color: rgba(255, 255, 255, 0.1);
        }

        td{
            padding: 5px;
            text-align: left;
            cursor: pointer;
        }

        
        .name-column{
            width: 100%;
        }

        .checkbox-column{
            width: 20px;
            text-align: center;
        }

        .modif-column{
            width: 100px;
        }
        

        thead{
            font-size: 0.6em;
            opacity: 0.5;

            td{
                border: none;
            }
        }
    }

    .add-btn{
        margin-top: 2em;
        padding: 15px;
        border: none;
        outline: none;
        background-color: $main-fond-light2;
        color: white;
        font-family: $text-family;
        text-transform: uppercase;
    }

    .btn-deco{
        position: absolute;
        bottom: 1em;
        right: 1em;
        background-color: transparent;
        border: none;
        outline: none;
        color: white;
        font-size: 0.7em;
        font-family: $text-family;
    }
}