.container-popup{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 90;
    cursor: pointer;
    top: 0;

    .popup{
        background-color: $main-fond-light2;
        position: absolute;
        max-width: 300px;
        padding: 1em;
        right: 1em;
        bottom: 1em;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        cursor:default;

        .header{
            text-align: right;
            font-size: 1.1em;
            text-transform: uppercase;
            letter-spacing: 0.5em;
            margin-bottom: 1em;

            .contain-close{
                position: absolute;
                top: 1em;
                right: 1em;
                cursor: pointer;
            }
        }

        form{
            display: flex;
            flex-direction: column;
            width: 100%;

            label
            {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin: 0.5em 0;

                textarea, input{
                    background: rgba(255, 255, 255, 0.2);
                    border: none;
                    outline: none;
                    padding: 1em;
                    width: 100%;
                    color: white;
                    font-family: $text-family;
                    font-size: 1em;
                }

                textarea{
                    height: 300px;
                }

                .error{
                    border: 1px solid $error-color;
                }
            }

            .container-onglets{
                display: flex;

                .onglet{
                    width: 100%;
                    flex-grow: 3;
                    opacity: 0.3;
                    cursor: pointer;
                    padding: 5px;
                    color: black;
                }

                .onglet.select{
                    opacity: 1;
                }

                .onglet.intrigue{
                    background-color: $color-intrigue;
                }
                .onglet.personnage{
                    background-color: $color-personnage;
                }
                .onglet.place{
                    background-color: $color-place;
                }
            }

            .message-error{
                height: 1em;
                margin: 5px 0;
            }

            input[type="submit"]{
                padding: 5px;
                background-color: white;
                color: $main-fond;
                outline: none;
                border: none;
                font-family: $text-family;
                margin: 1em 0;
                cursor: pointer;
            }
        }

        .inside-popup{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .response{
            text-align: right;
        }
    }
}