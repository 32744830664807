.container-btn-add{
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;

    p{
        text-transform: uppercase;
        letter-spacing: 0.5em;
        font-size: 0.7em;

    }
}